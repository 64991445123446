<template lang="pug">
    .welcome-anfas
        transition(name="fadeIn")
            loading(v-if="isLoading")._loading
        welcome-info
            template(v-slot:title) {{ locale("title") }}
            template(v-slot:subtitle)
                span(v-html="locale('subtitle')")
            template(v-slot:content)
                ._title {{ locale("upload-photos-title") }}
                change-photos(:show-gift="false" @photo-upload-error="errorHandler" :hideExpample="photosReady")._component
                ._footer(:class="{mini: photosReady}")
                    //ui-button(:text="locale('next-btn')"  :arrow="true" :disabled="!photosReady" @click.native="generateDescription")._btn
                    ui-button(:text="locale('skip-btn')" v-if="(+selectedOnBoardingType === 60 || +selectedOnBoardingType === 62) && !myUser.isUsePromo" type="bordered" color="gray" @click.native="skip")._btn
</template>

<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import ChangePhotos from "@/components/person/change-photos/change-photos";
import gql from "graphql-tag";
import onBoardingCompleteStepMutation from "@/graphql/mutations/onBoardingCompleteStep.mutation.graphql";
import personCreateDescriptionMutation from "@/graphql/mutations/personCreateDescription.mutation.graphql";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "WelcomePhotos",
    components: {UiButton, ChangePhotos, WelcomeInfo},
    data() {
        return {
            isLoading: false,
            anfasChange: false,
            profileChange: false,
            selectedOnBoardingType: 0,
        };
    },
    computed: {
        anfas: function () {
            const photo = this.$store.getters["user/photos"]["anfas"];
            return photo?.includes("dummy") ? null : photo;
        },
        profile: function () {
            const photo = this.$store.getters["user/photos"]["profile"];
            return photo?.includes("dummy") ? null : photo;
        },
        photosReady: (state) => (state.anfasChange || state.anfas) && (state.profileChange || state.profile),
        mode: (state) =>
            state.myUser.person.extras.external_id
                ? "RECREATE_BY_ID"
                : "RECREATE_BY_PHOTOS",
    },
    watch: {
        anfas() {
            this.anfasChange = true;
        },
        profile() {
            this.profileChange = true;
        },
        photosReady(status) {
            if (status) {
                this.generateDescription();
            }
        }
    },
    mounted() {
        this.selectedOnBoardingType = localStorage.getItem(
            "selectedOnBoardingType",
        );
        this.$store.dispatch("set", {
            name: "onboardingCtrlStepNumber",
            value: 3,
        });

        if (this.anfas && this.profile) {
            this.generateDescription();
        }
    },
    methods: {
        async skip(complete = false) {
            if (+this.selectedOnBoardingType === 60) {
                localStorage.setItem("photo-skipped", "true");
            }
            if (+this.selectedOnBoardingType === 61) {
                this.yandexGoal("psychotype_photo_uploaded");
            }
            if (complete) {
                localStorage.setItem("photo-skipped", "true");
                await this.completeStep();
            }
            return this.redirectController();
        },
        async generateDescription() {
            this.isLoading = true;
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${personCreateDescriptionMutation}
                    `,
                    variables: {
                        personId: this.myPerson.uuid,
                        mode: this.mode,
                    },
                })
                .then((r) => {
                    const taskId = r.data.personCreateDescription.taskId;
                    if (taskId) {
                        this.taskResponseHandler(taskId);
                    }
                })
                .catch((e) => {
                    console.log(e.graphQLErrors);
                    const code = e.graphQLErrors[0].extensions.code;
                    if (code === 1007) {
                        this.skip();
                    }
                });
        },
        async taskResponseHandler(taskId) {
            const task = await this.checkTask(taskId);
            if (task.status === "PENDING" || task.status === "RUNNING") {
                setTimeout(() => {
                    this.taskResponseHandler(taskId);
                }, 1000);
            }
            if (task.status === "COMPLETED") {
                await this.skip(true);
            }
            if (task.status === "ERROR") {
                this.$msgBox(
                    this.localeError("error"),
                    this.localeError("createDescriptionError"),
                );
            }
        },
        async completeStep() {
            this.isLoading = true;
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 5,
                        result: 1,
                    },
                })
                .catch((e) => {
                    const code = e.graphQLErrors[0].extensions.code;
                    if (code === 1007) {
                        this.skip();
                    }
                });
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${onBoardingCompleteStepMutation}
                    `,
                    variables: {
                        step: 6,
                        result: 1,
                    },
                })
                .catch((e) => {
                    const code = e.graphQLErrors[0].extensions.code;
                    if (code === 1007) {
                        this.skip();
                    }
                });

            await this.redirectController();
        },
        errorHandler(code, result) {
            if (code === 1212) {
                this.$router.push(
                    `/welcome/profile-already-exist?code=${result}`,
                );
            }
        },
    },
};
</script>
<style lang="scss">
.welcome-anfas {
    position: relative;

    &__title {
        font-size: 24px;
        line-height: 1.5;
        font-weight: bold;
        @media (max-width: 380px) {
            font-size: 18px;
        }
    }

    &__btn {
        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 50px;

        &.mini {
            margin-top: 0;
        }
    }

    .loading {
        position: absolute;
        background: #000;
        opacity: 1;
    }
}

.welcome-anfas-btn {
    margin-top: 30px;
}
</style>
